.o-button {
  background: #CCA352;
  border-radius: 40px;
  height: 48px;
  color: #FFF;
  line-height: 48px;
  padding: 0 24px;

  @apply hover:bg-roti-200;

  &:disabled {
    background: rgba(128,128,128,0.25);
    cursor: default;
    pointer-events: none;
  }

  i ~ span {
    @apply md:ml-2;
  }

  span {
    @apply hidden md:inline md:ml-2;
  }

}

button span {
  @apply ml-2;
}
