.o-table {

  overflow: auto;
  scroll-snap-type: x mandatory;
  height: 80vh;

  table {
    @apply w-full;
  }

  &__header {
    // @apply grid gap-4 uppercase py-5 px-8 text-sm font-medium bg-roti-100;
  }

  &__row {

  }

  th, td {
    background-clip: padding-box;
    scroll-snap-align: start;
  }


  th {
    @apply uppercase py-5 px-8 text-sm font-medium bg-roti-100 whitespace-nowrap text-left;

    position: sticky;
    top: 0;
    border-top: 0;
    background-clip: padding-box;
  }

  tbody {
    tr {
      @apply bg-white hover:bg-roti-50;
    }
  }

  td {
    @apply py-5 px-8 whitespace-nowrap border-b;
  }

  &__footer {
    text-align: center;
  }
}
