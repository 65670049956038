.o-input {
  &__text {
    @apply  w-full border px-3 py-3 relative placeholder-gray-400 text-gray-700 rounded bg-roti-50 text-sm border-roti-100 focus:outline-none focus:ring;

    ~ span {
      @apply absolute right-0 bottom-0 transform -translate-x-full -translate-y-2/4;
    }
  }

  &__wrapper {
    @apply relative my-4;
    .o-group & {
      .o-input__text {
        width: 100%;
      }
    }
  }

  &__radiobutton {
    line-height: 24px;
    @apply py-1;
    label {
      font-weight: 400;
    }

    & + img {
      @apply ml-8;
    }
  }

  &__textarea {
    @apply  w-full border px-3 py-3 relative placeholder-gray-400 text-gray-700 rounded bg-roti-50 text-sm border-roti-100 focus:outline-none focus:ring;
  }
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  line-height: 24px;
  display: flex;
  color: #666;
  margin-bottom: 0;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  @apply border-roti-150 bg-roti-150;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:before {
  @apply border-roti-150 bg-roti-150;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #FFF;
  position: absolute;
  display: block;
  top: 12px;
  left: 12px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}
[type="radio"]:checked + label:after {
  opacity: 1;
}
