@page { size: auto;  margin: 0; }
@media print {
  body {-webkit-print-color-adjust: exact;}

  * {
    font-size: 11px !important;
  }

  .o-page__header .o-button, // action buttons on heade
  .o-page__header .o-action__icon,
  .o-selection:not(.o-selection--checked),
  input[type="radio"]:checked + label::after,
  input[type="radio"]:checked + label::before {
    display: none;
  }

  .o-selection--checked input[type="radio"]:checked + label::after,
  .o-selection--checked input[type="radio"]:checked + label::before {
    display: none;
  }

  .o-selection {
    
    input[type="radio"] {
      display: inline;
      position: relative;
      left: auto;
    }
    label,
    label::after,
    label::before {
      display: none;
    }
  }

  input[type="radio"]:checked + label {
    padding-left: 0;
  }

  // Make input labels inline
  .o-input__wrapper {
    * {
      display: inline;
      width: auto !important;
    }
     
    .o-input__text ~ span {
      position: relative;
    }
  }

  .o-input__text {
    background: none;
  }

  .o-input__radiobutton ~ img {
    display: none;
  }
  .o-input__radiobutton--checked ~ img {
    display: block;
  }

  .o-section {
    max-height: 3000px;
    padding: 0;
    margin:0;
    clear: both;
  }
  .o-input__text,
  .o-select > div,
  .o-input__textarea  {
    border: 0;
    padding: 0;
  }
  .o-input__wrapper {
    margin: 0;
  }

  h2, h3 {
    font-size: 18px;
    @apply m-0 border font-bold;
  }
}
