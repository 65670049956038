.o-page {

  &__header {
    @apply  py-4 px-2 bg-white z-10;
    box-shadow: 0px 1px 0px #E5E4E1;

    .o-container {
      @apply flex flex-row justify-between;
    }
  }

  &__actions {
    @apply md:flex flex-row justify-between md:pt-8 pb-6;
  }

  &__main {
    scroll-behavior: smooth;
    @apply flex-1 overflow-y-auto px-3 py-4;
  }
}
