/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind screens;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

@import "elements/body";
@import "elements/label";
@import "elements/headings";


@import "objects/table";
@import "objects/selection";
@import "objects/pager";
@import "objects/action";
@import "objects/section";
@import "objects/select";
@import "objects/page";
@import "objects/container";
@import "objects/input";
@import "objects/card";
@import "objects/button";
@import "print/print";
