h1 {
  line-height: 1.3;
  @apply text-2xl md:text-4xl mb-4 md:mb-0;
}


h2 {
  font-size: 28px;
  @apply mb-2;
}


h3 {
  font-size: 24px;
  @apply mb-2;
}
