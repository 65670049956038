.o-card {
  @apply bg-white rounded shadow-sm;

  &__image {
    @apply p-2;
  }

  &__img {
    @apply  rounded w-full;
  }
  &__name {
    @apply pb-2 text-center;
  }
}
